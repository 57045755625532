<template>
  <div class="rechargeriew-chagriew">
    <en-table-layout
      :tableData="tableData.data"
      :loading="loading"
      @selection-change="handleSelectionChange"
    >
      <template slot="toolbar">
        <el-form-item label="状态">
          <el-select
            size="small"
            style="width: 120px"
            v-model="params.audit_status"
            placeholder="请选择"
            clearable
          >
            <el-option
              label="全部"
              value=""
            />
            <el-option
              label="待审核"
              value="0"
            />
            <el-option
              label="审核通过"
              value="1"
            />
            <el-option
              label="审核未通过"
              value="2"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="审核时间">
          <el-date-picker
            style="width: 130px"
            size="small"
            v-model="value1"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="">
          <el-input
            size="small"
            placeholder="店铺名称"
            v-model="params.shop_name"
            clearable
          ></el-input>
        </el-form-item>

        <el-button
          @click="GET_AuditList"
          type="primary"
          size="small"
        >搜索
        </el-button>
        <div class="col"></div>
        <el-button
          @click="handleAudit(idsList, true)"
          size="small"
          type="primary"
        >批量审核
        </el-button>
<!--        <el-button-->
<!--          @click="handleAuditDelete(idsList, true)"-->
<!--          size="small"-->
<!--          type="primary"-->
<!--        >批量删除-->
<!--        </el-button>-->
        <el-button
          @click="handleExportOrders"
          type="primary"
          size="small"
        >导出数据
        </el-button>
      </template>
      <template slot="table-columns">
        <el-table-column
          type="selection"
          width="55"
          fixed="left"
        ></el-table-column>
        <!--质保金图片-->
        <el-table-column
          prop="shop_name"
          label="供应商名称"
          min-width="200"
          fixed="left"
        />
        <el-table-column
          label="账户余额"
          width="120"
          fixed="left"
        >
          <template slot-scope="scope">
            {{ scope.row.now_price | unitPrice('￥') }}
          </template>
        </el-table-column>
        <el-table-column label="申请内容" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.service_name }}
          </template>
        </el-table-column>
        <el-table-column label="申请时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <!--质保金状态-->
        <el-table-column
          prop="audit_status"
          label="状态"
          width="120"
        >
          <template slot-scope="scope">
            {{
              scope.row.audit_status === 0
                ? '待审核'
                : scope.row.audit_status === 1
                ? '审核通过'
                : '未通过'
            }}
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column
          label="操作"
          width="145"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleAudit([scope.row.id], false, scope.row)"
              v-if="scope.row.audit_status === 0"
            >审核
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="handleClickLook(scope.row)"
            >查看
            </el-button>
<!--            <el-button-->
<!--              size="mini"-->
<!--              type="danger"-->
<!--              @click="handleAuditDelete([scope.row.id])"-->
<!--            >删除-->
<!--            </el-button>-->
          </template>
        </el-table-column>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="MixinPageSizes"
        :page-size="tableData.page_size"
        :layout="MixinTableLayout"
        :total="tableData.data_total"
        background
      >
      </el-pagination>
    </en-table-layout>

    <!--审核质保金 dialog-->
    <el-dialog-x-dialog
      :title="dialogGoodsAuditTitle"
      :visible.sync="dialogAuditVisible"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="orderAuditForm"
        :rules="goodsAuditRules"
        ref="orderAuditForm"
        label-width="100px"
      >
        <!--是否通过=-->
        <el-form-item
          v-if="orderAuditForm.shop_name"
          label="申请供应商"
          prop="shop_name"
          style="margin-bottom: 0"
        >
          <span>{{ orderAuditForm.shop_name }}</span>
        </el-form-item>

        <el-form-item
          label="当前质保金余额"
          prop="now_price"
          v-if="orderAuditForm.now_price"
          style="margin-bottom: 0"
        >
          <span>{{ orderAuditForm.now_price | unitPrice('￥') }}</span>
        </el-form-item>

        <el-form-item
          label="充值金额"
          prop="price"
          v-if="orderAuditForm.price"
          style="margin-bottom: 0"
        >
          <span>{{ orderAuditForm.price | unitPrice('￥') }}</span>
        </el-form-item>

        <el-form-item
          label="是否通过"
          prop="audit_status"
          style="margin-bottom: 0"
        >
          <el-radio-group v-model="orderAuditForm.status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="0">不通过</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="充值截图"
          prop="pay_img"
          v-if="orderAuditForm.pay_img"
          style="margin-bottom: 0"
        >
          <img
            :src="orderAuditForm.pay_img"
            alt=""
            class="pass_img"
          />
        </el-form-item>

        <el-form-item
          label="备注信息"
          prop="reason"
          style="margin-bottom: 0"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入审核备注(120字以内)"
            :maxlength="120"
            v-model="orderAuditForm.reason"
          >
          </el-input>
        </el-form-item>

        <el-form-item style="text-align:right;">
          <el-button
            @click="dialogAuditVisible = false"
            size="small"
          >取 消
          </el-button>
          <el-button
            type="primary"
            @click="submitorderAuditForm"
            size="small"
          >确 定
          </el-button>
        </el-form-item>
      </el-form>

    </el-dialog-x-dialog>
    <!-- 查看dialog -->
    <el-dialog-x-dialog
      :title="dialogGoodsAuditTitle"
      :visible.sync="dialogGoodspass"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="orderAuditForm"
        :rules="goodsAuditRules"
        ref="orderAuditForm"
        label-width="120px"
      >
        <!--是否通过=-->
        <el-form-item
          label="申请供应商"
          prop="pass"
        >
          <span>{{ lookObj.shop_name }}</span>
        </el-form-item>
        <el-form-item
          label="当前质保金余额"
          prop="pass"
        >
          <span>{{ lookObj.now_price | unitPrice('￥') }}</span>
        </el-form-item>
        <el-form-item
          label="充值金额"
          prop="pass"
        >
          <span>{{ lookObj.price | unitPrice('￥') }}</span>
        </el-form-item>
        <el-form-item
          label="审核状态"
          prop="pass"
        >
          <span>{{
              lookObj.audit_status == 0
                ? '待审核'
                : lookObj.audit_status == 1
                ? '审核通过'
                : '未通过'
            }}</span>
        </el-form-item>
        <el-form-item
          label="备注原因"
          prop="reason"
        >
          <span>{{ lookObj.reason ? lookObj.reason : '无' }}</span>
        </el-form-item>
        <el-form-item
          label="充值截图"
          prop="pass"
          v-if="lookObj.pay_img"
        >
          <img
            :src="lookObj.pay_img"
            @click="handleImgLook(lookObj.pay_img)"
            alt=""
            class="pass_img"
          />
          <!-- <el-button type="primary" size="small" @click="handleImgLook(lookObj.pay_img)">查看大图</el-button> -->
        </el-form-item>
        <!-- <el-form-item style="text-align:right;">
          <el-button
            @click="dialogGoodspass = false"
            size="small"
          >取 消</el-button>
          <el-button
            type="primary"
            @click="dialogGoodspass = false"
            size="small"
          >确 定</el-button>
        </el-form-item> -->
      </el-form>
    </el-dialog-x-dialog>

    <!-- 查看大图 -->
    <el-dialog
      :visible.sync="centerDialogVisible"
      modal
      close-on-click-modal
      custom-class="dialog"
    >
      <el-carousel
        :autoplay="false"
        arrow="never"
      >
        <el-carousel-item
          v-for="item in centerDialogList"
          :key="item"
        >
          <img
            :src="item"
            style="width: 100%; height: 100%"
          />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Service from '@/api/order';
import {Foundation} from '@/../ui-utils';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  name: 'goodsAudit',
  components: {EnTableLayout},
  data() {
    return {
      value1: '',
      idsList: [],
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        shop_name: '',
        audit_status: '',
        start_time: '',
        end_time: '',
      },
      // 质保金数据
      tableData: {},
      // 审核质保金 表单
      orderAuditForm: {
        message: '',
        audit_status: 1,
      },
      // 审核质保金 表单校验
      goodsAuditRules: {
        message: [
          {
            required: false,
            message: '请输入审核备注！',
            trigger: 'blur'
          },
        ],
      },
      // 审核质保金 dialog
      dialogAuditVisible: false,
      // 审核质保金 dialogTitle
      dialogGoodsAuditTitle: '审核质保金',
      //查看质保金变更记录
      dialogGoodspass: false,
      tableDatapass: [],
      lookObj: {},

      centerDialogVisible: false, // 显示查看器
      centerDialogList: [],
    };
  },
  mounted() {
    this.GET_AuditList();
  },
  activated() {
    this.GET_AuditList();
  },
  watch: {
    'orderAuditForm.pass': function (newVal) {
      this.goodsAuditRules.message[0].required = newVal === 0;
    },
  },
  methods: {
    handleImgLook(url) {
      console.log(url);
      this.centerDialogList = [url];
      // this.showViewer = true;
      this.centerDialogVisible = true;
    },
    //   查看按钮
    handleClickLook(row) {
      this.dialogGoodspass = true;
      this.lookObj = row;
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_AuditList();
    },
    //导出数据
    handleExportOrders() {
      this.params.start_time = this.value1
        ? new Date(this.value1).getTime() / 1000
        : '';
      this.params.end_time = this.params.start_time;
      const params = {
        ...this.params,
        page_no: 1,
        page_size: 9999,
      };
      this.params.page_no = 1;
      this.params.page_size = 9999;
      API_Service.auditRetentionMoneyList(params)
        .then((response) => {
          this.loading = false;
          const json = {
            sheet_name: '质保金充值审核列表',
            sheet_values: response.data.map((item) => ({
              供应商名称: item.shop_name,
              账户余额: Foundation.formatPrice(item.now_price),
              申请内容: item.service_name,
              申请时间: Foundation.unixToDate(item.create_time),
              状态:
                item.audit_status == 0
                  ? '待审核'
                  : item.audit_status == 1
                  ? '审核通过'
                  : '未通过',
            })),
          };
          this.MixinExportJosnToExcel(json, '质保金充值审核列表');
        })
        .catch(() => (this.loading = false));
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_AuditList();
    },

    /** 搜索事件触发 */
    searchEvent(data) {
      this.params = {
        ...this.params,
        keyword: data,
      };
      Object.keys(this.advancedForm).forEach((key) => delete this.params[key]);
      this.GET_AuditList();
    },

    /** 高级搜索中 分类选择组件值发生改变 */
    categoryChanged(data) {
      this.advancedForm.category_path = data.category_path;
    },
    /** 删除质保金 */
    handleAuditDelete(ids, type) {
      if (ids.length) {
        this.idsList = ids;
        this.$confirm(`确定要删除${type ? '此' : '所选'}申请吗？`, '提示', {
          type: 'warning',
        })
          .then(() => {
            API_Service.deleteTradeServiceOrder(this.idsList).then(() => {
              this.$message.success('删除成功！');
              this.GET_AuditList();
            });
          })
          .catch(() => {
          });
      } else {
        this.$message.error('请勾选质保金');
      }
    },

    /** 审核质保金 */
    handleAudit(ids, type, row) {
      this.dialogGoodsAuditTitle = type ? '批量审核' : '审核质保金';
      if (ids.length) {
        this.idsList = ids;
        this.orderAuditForm = {
          ids: ids,
          reason: '',
          status: 1,
        };
        this.orderAuditForm = {
          ...this.orderAuditForm,
          ...row,
        };
        console.log(this.orderAuditForm);
        this.dialogAuditVisible = true;
      } else {
        this.$message.error('请勾选质保金');
      }
    },

    /** 审核质保金 表单提交 */
    submitorderAuditForm() {
      if (!this.idsList) {
        this.$message.error('请勾选质保金！');
      }
      this.$refs['orderAuditForm'].validate((valid) => {
        if (valid) {
          console.log(this.idsList);
          API_Service.auditBatchService(this.idsList, this.orderAuditForm).then(
            (response) => {
              this.dialogAuditVisible = false;
              this.$message.success('审核完成！');
              this.GET_AuditList();
            }
          );
        } else {
          this.$message.error('表单填写有误，请核对！');
          return false;
        }
      });
    },

    /** 获取待审核质保金 */
    GET_AuditList() {
      this.loading = true;
      this.params.start_time = this.value1
        ? new Date(this.value1).getTime() / 1000
        : '';
      this.params.end_time = this.params.start_time;
      API_Service.auditRetentionMoneyList(this.params)
        .then((response) => {
          if (response.page_no !== 1 && response.data.length === 0) {
            this.params.page_no = response.page_no -= 1
            this.GET_AuditList()
          } else {
            this.loading = false;
            this.tableData = response;
          }
        })
        .catch(() => (this.loading = false));
    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.id);
      });
      this.idsList = ids;
    },
  },
};
</script>

<style lang="scss" scoped>
.pass_img {
  width: 100%;
  height: 200px;
  cursor: pointer;
}
</style>
